body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  display: flex;
  /* flex-direction: row; */
  height: 100vh;
  width: 100vw;
  /* background-color: blue; */
  margin: 0;
}

@font-face {
  font-family: "Graphik";

  src: local("Graphik"), url(./fonts/Graphik-Light.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: lighter;
  font-style: italic;
  src: local("Graphik"), url(./fonts/Graphik-LightItalic.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: 400;
  src: local("Graphik"), url(./fonts/Graphik-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-style: italic;
  font-weight: 400;
  src: local("Graphik"),
    url(./fonts/Graphik-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./fonts/Graphik-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-style: italic;
  src: local("Graphik"),
    url(./fonts/Graphik-RegularItalic.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: 400;
  font-style: italic;
  src: local("Graphik"),
    url(./fonts/Graphik-SemiboldItalic.otf) format("opentype");
}

@font-face {
  font-family: "InvescoEditor";
  font-weight: 900;
  src: local("InvescoEditor"),
    url(./fonts/InvescoEditor-Regular.otf) format("opentype");
}
